import { useQuery } from '@apollo/client';
import { AuditLogTable } from 'components/audit/audit-log-table';
import { DeleteCustomerButton } from 'components/delete-customer-button';
import { Loading } from 'components/loading';
import { config } from 'config';
import {
  CustomerQuery,
  CustomerQueryVariables,
  ProblemType,
} from 'graphql/types';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { dedupeArray, getProblemTypeFromString } from 'utils/misc';
import { useUrlQuery } from 'utils/use-url-query';
import { PaymentMethodSection } from './PaymentMethodSection';
import { AccessSection } from './accessSection';
import { ChatSection } from './chatSection';
import { ConsultationPanel as ConsultationSection } from './consultation-section/consultation-panel';
import { CustomerSection } from './customerSection';
import { IndividualHealthcareIdentifierSection } from './individualHealthcareIdentifierSection';
import { MedicalRecord } from './medical-record/medical-record';
import { MembershipPanel as MembershipSection } from './membershipSection';
import { OfferingSection } from './offeringSection';
import { ProblemTypeHeader } from './problemTypeHeader';
import { customerQuery } from './queries/customerQuery';
import { QuizWithNoConsult } from './quizTable';
import { ShippingAddressSection } from './shippingAddressSection';
import { ResidentialAddressSection } from './residentialAddressSection';
import { ShopfrontOrdersSection } from './shopfrontOrdersSection';
import { TrackingSection } from './trackingSection';
import { TreatmentSection } from './treatmentSection';
import { ProgressOverviewSection } from './progress-overview-section';
import {
  Customer,
  CustomerConsultationView,
  CustomerMembershipView,
  CustomerTreatmentJobView,
  CustomerTreatmentView,
  CustomerViewProblemType,
  OrdersTableItem,
  Treatment,
} from './types';
import { VerificationPanel as VerificationSection } from './verficationSection';
import clsx from 'clsx';
import { PrescribedSequenceSection } from './prescribedSequenceSection';
import { compareDesc } from 'date-fns';
import { v4 } from 'uuid';
import { NotesSection } from './notesSection';
import { CriticalNoteCard } from 'components/notes/critical-note/critical-note-card';
import ConsignmentsSection from './consignmentsSection';

const CustomerPage = () => {
  const urlQuery = useUrlQuery();
  const history = useHistory();
  const { customerId } = useParams<{ customerId: string }>();

  const activeTab = getProblemTypeFromString(urlQuery.get('pt') || undefined);

  const setActiveTab = React.useCallback(
    (problemType: ProblemType) => {
      history.replace({
        pathname: `/customers/${customerId}`,
        search: `?pt=${problemType}`,
      });
    },
    [customerId, history],
  );

  const {
    data,
    loading,
    refetch: refetchCustomer,
  } = useQuery<CustomerQuery, CustomerQueryVariables>(customerQuery, {
    variables: {
      where: { id: customerId },
      membershipEnabled: config.membershipEnabled,
      customerId: customerId,
    },
    fetchPolicy: 'cache-first',
  });
  const customer = data?.customer;

  const problemTypeData = React.useMemo(() => {
    if (customer) {
      return mapGraphQLUserToCustomerViewProblemType(customer);
    }
  }, [customer]);

  React.useEffect(() => {
    if (
      activeTab === undefined &&
      problemTypeData &&
      problemTypeData.length > 0
    ) {
      const problemTypeFromURL = getProblemTypeFromString(
        urlQuery.get('pt') || undefined,
      );

      if (
        problemTypeFromURL &&
        problemTypeData.map((p) => p.type).includes(problemTypeFromURL)
      ) {
        return;
      }

      setActiveTab(problemTypeData[0].type);
    }
  }, [activeTab, problemTypeData, urlQuery, setActiveTab]);

  if (loading) {
    return <Loading />;
  }

  if (!customer) {
    return <div className="font-medium pt-8">Patient not found</div>;
  }

  if (!problemTypeData) {
    return null;
  }

  let tabContent: CustomerViewProblemType | undefined;
  for (const pt of problemTypeData) {
    if (pt.type === activeTab) {
      tabContent = pt;
      break;
    }
  }

  const consignments = customer.conditions?.flatMap(
    (cond) => cond.consignments || [],
  );

  return (
    <div>
      <ProblemTypeHeader
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={problemTypeData.map((pt) => pt.type)}
      />
      <div className="flex">
        <div className="w-2/3 mr-4 space-y-4">
          {(() => {
            if (!tabContent) {
              return null;
            }

            const prescribedSequences = [];
            for (const c of tabContent.consultations) {
              if (c.prescribedSequences?.length) {
                prescribedSequences.push(...c.prescribedSequences);
              }
            }
            prescribedSequences.sort(
              (ps1, ps2) =>
                new Date(ps2.createdAt).getTime() -
                new Date(ps1.createdAt).getTime(),
            );

            return (
              <div key={tabContent.type}>
                {tabContent.quizApplication &&
                  tabContent.quizApplication.length > 0 && (
                    <QuizWithNoConsult quiz={tabContent.quizApplication} />
                  )}
                <ConsultationSection
                  customer={customer}
                  consultations={tabContent.consultations}
                  refetchCustomer={refetchCustomer}
                  problemType={tabContent.type}
                />
                <PrescribedSequenceSection
                  prescribedSequences={prescribedSequences}
                />
                <OfferingSection
                  purchaseGroups={tabContent.purchaseGroups}
                  customerId={customerId}
                />
                <ConsignmentsSection consignments={consignments} />
                <ProgressOverviewSection
                  tracker={customer.tracker}
                  problemType={tabContent.type}
                />
                <TrackingSection
                  customer={customer}
                  problemType={tabContent.type}
                />
                <ChatSection customer={customer} />
                {tabContent.treatments.length > 0 && (
                  <div
                    className={clsx(
                      'bg-white shadow overflow-hidden rounded my-4',
                    )}
                  >
                    {tabContent.treatments.map((t) => (
                      <TreatmentSection
                        key={t.id}
                        treatment={t}
                        customer={customer}
                        refetchCustomer={refetchCustomer}
                        showActions={!t.supersededByFlexiPlans}
                      />
                    ))}
                  </div>
                )}
                {config.membershipEnabled && tabContent.membership && (
                  <MembershipSection
                    membership={tabContent.membership}
                    onUpdated={refetchCustomer}
                  />
                )}
                <ShopfrontOrdersSection
                  shopfrontOrders={tabContent.shopfrontOrders}
                />
              </div>
            );
          })()}
          <div className="bg-white shadow overflow-hidden rounded">
            <AccessSection accesses={customer.accesses ?? []} />
          </div>

          <CriticalNoteCard customer={customer} />

          <NotesSection
            customer={customer}
            notes={data.filteredNotes ?? []}
            consultations={customer.consultations ?? []}
          />

          <div className="bg-white shadow overflow-hidden rounded">
            <AuditLogTable
              targetId={customer.id}
              customer={customer}
              targetType="USER"
            />
          </div>
        </div>
        <div className="w-1/3 space-y-4">
          <CustomerSection customer={customer} />
          <ShippingAddressSection customer={customer} />
          <ResidentialAddressSection
            customer={customer}
            refetchCustomer={refetchCustomer}
          />
          <PaymentMethodSection
            defaultPaymentMethod={customer.defaultPaymentGateway}
          />
          <VerificationSection customer={customer} />
          <MedicalRecord customer={customer} />
          {config.medicareEnabled && (
            <IndividualHealthcareIdentifierSection
              customer={customer}
              refetchCustomer={refetchCustomer}
            />
          )}
          <DeleteCustomerButton customer={customer} />
        </div>
      </div>
    </div>
  );
};

const extractProblemTypes = (array: { type: ProblemType }[]): ProblemType[] =>
  array.map((x) => x.type);

const mapGraphQLUserToCustomerViewProblemType = (
  user: Customer,
): CustomerViewProblemType[] => {
  const problems: {
    [type: string]: CustomerViewProblemType;
  } = Object.fromEntries(
    dedupeArray([
      ...extractProblemTypes(user.consultations),
      ...extractProblemTypes(user.treatments),
      ...(config.membershipEnabled
        ? extractProblemTypes(user.memberships ?? [])
        : []),
      ...(user.quizApplications?.map((item) => item.problemType) ?? []),
      ...user.ordersWithoutTreatment.flatMap((order) =>
        order.lineItems.flatMap((lineItem) =>
          // If there is no problem type for a product, default to OTC
          lineItem.variant.product.problemTypes.length
            ? lineItem.variant.product.problemTypes
            : 'DEPRECATED_OTC',
        ),
      ),
      ...(user.purchaseGroups?.flatMap(({ customerPurchases }) =>
        customerPurchases.flatMap((p) => p.offering?.problemTypes || []),
      ) || []),
      ...(user.conditions?.map((c) => c.problemType) || []),
    ]).map((type) => [
      type,
      {
        name: type,
        type: type,
        consultations: [],
        purchaseGroups: [],
        treatments: [],
        shopfrontOrders: [],
        quizApplication: [],
      },
    ]),
  );

  if (user.quizApplications) {
    for (const quiz of user.quizApplications) {
      const quizApplication = problems[quiz.problemType].quizApplication;
      if (quiz && quizApplication) {
        quizApplication.push(quiz);
      }
    }
  }

  for (const consultation of user.consultations) {
    problems[consultation.type].consultations.push(
      mapGraphQLConsultationToCustomerConsultationView(consultation),
    );
  }

  for (const treatment of user.treatments) {
    if (treatment?.plan) {
      problems[treatment.type].treatments.push(
        mapGraphQLTreatmentToCustomerTreatmentView(
          treatment,
          treatment.plan,
          treatment.product,
        ),
      );
    }
  }

  const pricingSessionPurchaseGroups = new Map<
    string,
    NonNullable<typeof user.purchaseGroups>
  >();

  for (const pg of user.purchaseGroups ?? []) {
    let sId = pg.pricingSessionId;
    if (!sId) {
      // Required as there are legacy users with purchase groups
      // that predate pricing sessions.
      sId = v4();
    }
    const curr = pricingSessionPurchaseGroups.get(sId) ?? [];
    curr.push(pg);
    pricingSessionPurchaseGroups.set(sId, curr);
  }

  for (const [, pgs] of pricingSessionPurchaseGroups.entries()) {
    pgs.sort((a, b) => {
      return compareDesc(new Date(a.createdAt), new Date(b.createdAt));
    });

    const [latestPurchaseGroup, ...rest] = pgs;
    for (const pt of new Set(
      latestPurchaseGroup.customerPurchases.flatMap(
        (p) => p.offering?.problemTypes || [],
      ),
    ) || []) {
      problems[pt].purchaseGroups.push({
        id: latestPurchaseGroup.id,
        unmetActivationRequirements:
          latestPurchaseGroup.unmetActivationRequirements ?? [],
        purchases: latestPurchaseGroup.customerPurchases.map((p) => ({
          id: p.id,
          name: p.offering?.friendlyName || '',
          advertisedName: p.offering?.advertisedName || '',
          status: p.status,
          cancelReason: p.cancelReason,
          sequenceContexts:
            p.contexts?.map((c) => ({
              id: c.id,
              name: c.sequence?.friendlyName || '',
              status: c.status,
              addon: c.addon,
              prescribedSequences: c.prescribedSequences,
            })) || [],
        })),
        overridden: rest.map((pg) => ({
          id: pg.id,
          unmetActivationRequirements: pg.unmetActivationRequirements ?? [],
          purchases: pg.customerPurchases.map((p) => ({
            id: p.id,
            name: p.offering?.friendlyName || '',
            advertisedName: p.offering?.advertisedName || '',
            status: p.status,
            cancelReason: p.cancelReason,
            sequenceContexts:
              p.contexts?.map((c) => ({
                id: c.id,
                name: c.sequence?.friendlyName || '',
                status: c.status,
                addon: c.addon,
                prescribedSequences: c.prescribedSequences,
              })) || [],
          })),
        })),
      });
    }
  }

  if (config.membershipEnabled && user.memberships) {
    for (const membership of user.memberships) {
      problems[membership.type].membership =
        mapGraphQLMembershipToCustomerMembershipView(membership);
    }
  }

  for (const order of user.ordersWithoutTreatment) {
    for (const lineItem of order.lineItems) {
      const problemTypes = [...lineItem.variant.product.problemTypes];
      if (!problemTypes.length) {
        // If there is no problem type for a product, default to OTC
        problemTypes.push('DEPRECATED_OTC');
      }
      for (const problemType of problemTypes) {
        problems[problemType].shopfrontOrders.push(
          mapOrderToOrdersTableItem((o) => {
            if (o.lineItems.length > 1) {
              return `Multiple (${o.lineItems.length})`;
            }
            return o.lineItems[0]?.variant.product?.name || '';
          })(order),
        );
      }
    }
  }

  const results = Object.values(problems);
  // Sort based on problem type to make sure the order doesn't change when update is done.
  results.sort((a, b) => {
    return a.type.localeCompare(b.type);
  });
  return results;
};

const mapGraphQLConsultationToCustomerConsultationView = (
  consultation: Customer['consultations'][number],
): CustomerConsultationView => ({
  id: consultation.id,
  completed: consultation.completedAt
    ? new Date(consultation.completedAt)
    : undefined,
  doctor: consultation.doctor?.clinicianName ?? undefined,
  status: consultation.status,
  createdAt: new Date(consultation.createdAt),
  isApproved: consultation.isApproved ?? false,
  prescribedSequences: consultation.prescribedSequences ?? [],
  purchasePrompt: consultation.purchasePrompt,
  callRecorded:
    consultation.phoneCalls?.some(
      (pc) => pc.status === 'COMPLETED' || pc.status === 'MANUAL_CONFIRMATION',
    ) ?? false,
  reviewReason: consultation.reviewReason,
  stage: consultation.stage,
});

const mapOrderToOrdersTableItem =
  (mapProduct: (o: Customer['ordersWithoutTreatment'][number]) => string) =>
  (o: Customer['ordersWithoutTreatment'][number]): OrdersTableItem => ({
    id: o.id,
    created: new Date(o.createdAt),
    updated: new Date(o.updatedAt),
    product: mapProduct(o),
    status: o.status || undefined,
    fulfillment: o.fulfillment || undefined,
    consultId: o.consultation?.id || '',
    gateway: o.payments[0]?.gateway,
  });

const mapGraphQLTreatmentToCustomerTreatmentView = (
  treatment: Treatment,
  plan: NonNullable<Treatment['plan']>,
  product: NonNullable<Treatment['product']>,
): CustomerTreatmentView => ({
  id: treatment.id,
  amount: plan.amount,
  name: product.name,
  productId: product.id,
  canReschedule: treatment.canReschedule,
  plan: plan.name,
  planId: plan.id,
  nextPaymentDue: treatment.nextPayment
    ? new Date(treatment.nextPayment)
    : undefined,
  refillsLeft: treatment.refillsLeft || undefined,
  repeats: treatment.repeats || undefined,
  status: treatment.status,
  created: new Date(treatment.createdAt),
  updated: new Date(treatment.updatedAt),
  canPause: treatment.canPause || false,
  canRefill: treatment.canRefill || false,
  canReview: treatment.canReview || false,
  canRevertReview: treatment.canRevertReview || false,
  supersededByFlexiPlans: treatment.supersededByFlexiPlans,
  customerStatedTreatmentStartedAt: treatment.customerStatedTreatmentStartedAt
    ? new Date(treatment.customerStatedTreatmentStartedAt)
    : undefined,
  type: treatment.type,
  completedOrders: treatment.orders.map(
    mapOrderToOrdersTableItem(
      (o) =>
        // sort line items so we can show the RX product
        [...o.lineItems].sort(({ variant }) =>
          variant?.product?.productType !== 'OTC' ? -1 : 1,
        )?.[0]?.variant.product?.name || '',
    ),
  ),
  jobs: treatment.jobs.map(
    (j): CustomerTreatmentJobView => ({
      id: j.id,
      updatedAt: new Date(j.updatedAt),
      type: j.type,
      scheduledAt: j.scheduledAt ? new Date(j.scheduledAt) : undefined,
      doneAt: j.doneAt ? new Date(j.doneAt) : undefined,
      message: j.message || undefined,
      source: j.source || undefined,
      failed: j.failed,
      createdAt: new Date(j.createdAt),
      data: j.data,
      orderId: j.order?.id ?? undefined,
      isReApprove: j.isReApprove ?? false,
      canceled: j.canceled ?? false,
      canReschedule: !treatment.supersededByFlexiPlans && !!j.canReschedule,
    }),
  ),
  otcSchedules: treatment.otcSchedules.map((s) => ({
    ...s,
    treatmentId: treatment.id,
    createdAt: new Date(s.createdAt),
    updatedAt: new Date(s.updatedAt),
  })),
  logs: treatment.logs,
  experimentPaymentPlan: treatment.experimentPaymentPlan ?? undefined,
  experimentAvailablePaymentPlans: treatment.experimentAvailablePaymentPlans,
  scriptId: treatment.scriptId ?? '',
  scriptExpiresAt: treatment?.script?.expiresAt,
  consultation: treatment.consultation
    ? { id: treatment.consultation.id }
    : undefined,
});

const mapGraphQLMembershipToCustomerMembershipView = (
  membership: NonNullable<Customer['memberships']>[number],
): CustomerMembershipView => {
  const latestTerm = membership.membershipTerms?.[0];
  const nextExpireJobDue = latestTerm?.jobs?.filter(
    (j) => j.type === 'MEMBERSHIP_EXPIRE' && !j.doneAt,
  )?.[0]?.scheduledAt;

  return {
    id: membership.id,
    userId: membership.userId,
    amount: latestTerm?.membershipFee,
    type: membership.type,
    isValid: latestTerm?.isValid,
    status: latestTerm?.status,
    subscriptionStatus: latestTerm?.subscriptionStatus,
    startDate: latestTerm?.startDate
      ? new Date(latestTerm?.startDate)
      : undefined,
    endDate: latestTerm?.endDate ? new Date(latestTerm?.endDate) : undefined,
    nextPaymentDue:
      latestTerm.subscriptionStatus === 'ACTIVE' && nextExpireJobDue
        ? new Date(nextExpireJobDue)
        : undefined,
    jobs: membership.membershipTerms?.[0]?.jobs?.map(
      (j): CustomerTreatmentJobView => ({
        id: j.id,
        updatedAt: new Date(j.updatedAt),
        type: j.type,
        scheduledAt: j.scheduledAt ? new Date(j.scheduledAt) : undefined,
        doneAt: j.doneAt ? new Date(j.doneAt) : undefined,
        message: j.message || undefined,
        source: j.source || undefined,
        failed: j.failed,
        createdAt: new Date(j.createdAt),
        data: j.data,
        orderId: j.order?.id ?? undefined,
        isReApprove: j.isReApprove ?? false,
        canceled: j.canceled ?? false,
        canReschedule: false,
      }),
    ),
  };
};

export default CustomerPage;
